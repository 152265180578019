import React from "react";

const UserIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.976036 10.3096C1.60116 9.68453 2.449 9.33334 3.33306 9.33334H8.66639C9.55045 9.33334 10.3983 9.68453 11.0234 10.3096C11.6485 10.9348 11.9997 11.7826 11.9997 12.6667V14H10.6664V12.6667C10.6664 12.1362 10.4557 11.6275 10.0806 11.2525C9.70553 10.8774 9.19682 10.6667 8.66639 10.6667H3.33306C2.80263 10.6667 2.29392 10.8774 1.91885 11.2525C1.54377 11.6275 1.33306 12.1362 1.33306 12.6667V14H-0.000274658V12.6667C-0.000274658 11.7826 0.350915 10.9348 0.976036 10.3096Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.00026 2.66667C4.89569 2.66667 4.00026 3.5621 4.00026 4.66667C4.00026 5.77124 4.89569 6.66667 6.00026 6.66667C7.10483 6.66667 8.00026 5.77124 8.00026 4.66667C8.00026 3.5621 7.10483 2.66667 6.00026 2.66667ZM2.66693 4.66667C2.66693 2.82572 4.15932 1.33334 6.00026 1.33334C7.84121 1.33334 9.3336 2.82572 9.3336 4.66667C9.3336 6.50762 7.84121 8 6.00026 8C4.15932 8 2.66693 6.50762 2.66693 4.66667Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.5003 9.44122C14.2154 9.62586 14.8489 10.0428 15.3014 10.6265C15.7539 11.2102 15.9997 11.9277 16.0003 12.6662L16.0003 14.0001H14.6669V12.6669C14.6665 12.2239 14.519 11.7935 14.2476 11.4434C13.9761 11.0931 13.596 10.843 13.1669 10.7322L13.5003 9.44122Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5849 3.44371C11.3126 3.0929 10.9312 2.8427 10.501 2.73255L10.8318 1.44089C11.5488 1.62447 12.1843 2.04147 12.6381 2.62615C13.092 3.21082 13.3383 3.92991 13.3383 4.67005C13.3383 5.4102 13.092 6.12929 12.6381 6.71396C12.1843 7.29864 11.5488 7.71564 10.8318 7.89922L10.501 6.60755C10.9312 6.4974 11.3126 6.2472 11.5849 5.8964C11.8572 5.54559 12.005 5.11414 12.005 4.67005C12.005 4.22597 11.8572 3.79451 11.5849 3.44371Z" fill="#20222B"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default UserIcon;
