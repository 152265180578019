import React from "react";

const BriefIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.66667 5.33333C2.29848 5.33333 2.00001 5.63181 2.00001 6V12.6667C2.00001 13.0349 2.29848 13.3333 2.66667 13.3333H13.3333C13.7015 13.3333 14 13.0349 14 12.6667V6C14 5.63181 13.7015 5.33333 13.3333 5.33333H2.66667ZM0.666672 6C0.666672 4.89543 1.5621 4 2.66667 4H13.3333C14.4379 4 15.3333 4.89543 15.3333 6V12.6667C15.3333 13.7712 14.4379 14.6667 13.3333 14.6667H2.66667C1.5621 14.6667 0.666672 13.7712 0.666672 12.6667V6Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.25246 1.91912C5.62753 1.54405 6.13624 1.33334 6.66667 1.33334H9.33334C9.86377 1.33334 10.3725 1.54405 10.7476 1.91912C11.1226 2.29419 11.3333 2.8029 11.3333 3.33334V14H10V3.33334C10 3.15653 9.92977 2.98696 9.80474 2.86193C9.67972 2.73691 9.51015 2.66667 9.33334 2.66667H6.66667C6.48986 2.66667 6.32029 2.73691 6.19527 2.86193C6.07024 2.98696 6.00001 3.15653 6.00001 3.33334V14H4.66667V3.33334C4.66667 2.8029 4.87739 2.29419 5.25246 1.91912Z" fill="#20222B"/>
</svg>

);

export default BriefIcon;
