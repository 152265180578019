import React from "react";

const HeartIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.6413 1.66208C10.1672 1.44418 10.7308 1.33203 11.3001 1.33203C11.8693 1.33203 12.433 1.44418 12.9588 1.66208C13.4847 1.87998 13.9625 2.19935 14.3649 2.60195C14.7674 3.00432 15.0867 3.48204 15.3046 4.00782C15.5225 4.53369 15.6346 5.09735 15.6346 5.66658C15.6346 6.23581 15.5225 6.79947 15.3046 7.32535C15.0867 7.85117 14.7674 8.32893 14.3648 8.73132C14.3648 8.73136 14.3648 8.73128 14.3648 8.73132L8.00007 15.0961L1.63533 8.73132C0.822512 7.9185 0.365875 6.81608 0.365875 5.66658C0.365875 4.51708 0.822512 3.41466 1.63533 2.60184C2.44815 1.78902 3.55057 1.33239 4.70007 1.33239C5.84957 1.33239 6.95199 1.78902 7.76481 2.60184L8.00007 2.83711L8.23522 2.60195C8.23526 2.60192 8.23518 2.60199 8.23522 2.60195C8.63761 2.1994 9.11548 1.87996 9.6413 1.66208ZM11.3001 2.66536C10.906 2.66536 10.5158 2.74301 10.1517 2.89386C9.78763 3.04471 9.45685 3.26582 9.17825 3.54454L8.00007 4.72272L6.822 3.54465C6.25923 2.98188 5.49595 2.66572 4.70007 2.66572C3.90419 2.66572 3.14091 2.98188 2.57814 3.54465C2.01537 4.10742 1.69921 4.8707 1.69921 5.66658C1.69921 6.46246 2.01537 7.22574 2.57814 7.78851L8.00007 13.2104L13.422 7.78851C13.7007 7.50992 13.9219 7.17903 14.0728 6.81496C14.2236 6.45089 14.3013 6.06066 14.3013 5.66658C14.3013 5.2725 14.2236 4.88228 14.0728 4.51821C13.9219 4.15414 13.7008 3.82336 13.4221 3.54476C13.1435 3.26604 12.8125 3.04471 12.4484 2.89386C12.0844 2.74301 11.6942 2.66536 11.3001 2.66536Z" fill="#20222B"/>
</svg>

);

export default HeartIcon;
