/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { rem } from "@src/theme";

import BookIc from "./icons/book";
import BriefIc from "./icons/brief";
import ChartIc from "./icons/chart";
import DollarIc from "./icons/dollar";
import HeartIc from "./icons/heart";
import HouseIc from "./icons/house";
import SunIc from "./icons/sun";
import UserIc from "./icons/user";

const iconMap = {
  "Community development": <UserIc />,
  "Health & wellbeing": <HeartIc />,
  Housing: <HouseIc />,
  "Learning & education": <BookIc />,
  "Employment & careers": <BriefIc />,
  "Economic impacts": <DollarIc />,
  "Environmental impacts": <SunIc />,
  "Impact measurement": <ChartIc />,
};

interface ComponentProps {
  link: string;
  label: string;
}
const IconCard = ({ link, label }: ComponentProps) => {
  const ICO = iconMap[label]
    ? iconMap[label]
    : iconMap["Community development"];

  return (
    <li
      sx={{
        display: "inline-block",
        color: "black",
        "> *": {
          color: "black",
        },
        mr: "8px",
        mb: "8px",
        fontSize: [rem(12), null, rem(16)],
        fontWeight: 600,
        a: {
          textDecoration: "none",
          height: "100%",
          width: "100%",
        },
      }}
    >
      <Link
        to={link}
        className="link-hover bg-hover"
        sx={{
          variant: "typography.headingS",
          padding: "14px 16px",
          bg: "lightGrey",
          display: "block",
          svg: {
            mr: rem(5),
            transform: "translateY(2px)",
          },
          span: {
            display: "inline-block",
            transform: ["translateY(-1px)", null, "none"],
          },
        }}
      >
        {ICO} <span className="link-hover bg-hover">{label}</span>
      </Link>
    </li>
  );
};
export default IconCard;
