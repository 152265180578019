import React from "react";

const BookIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 1.33334H5.33334C6.21739 1.33334 7.06524 1.68453 7.69036 2.30965C8.31548 2.93477 8.66667 3.78261 8.66667 4.66667V14H7.33334C7.33334 13.6464 7.19286 13.3072 6.94281 13.0572C6.69277 12.8071 6.35363 12.6667 6 12.6667H0.666672V1.33334ZM7.33334 11.6906V4.66667C7.33334 4.13624 7.12262 3.62753 6.74755 3.25246C6.37248 2.87738 5.86377 2.66667 5.33334 2.66667H2.00001V11.3333H6C6.47216 11.3333 6.9314 11.4586 7.33334 11.6906Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.30964 2.30965C8.93476 1.68453 9.78261 1.33334 10.6667 1.33334H15.3333V12.6667H9.99999C9.64637 12.6667 9.30723 12.8071 9.05719 13.0572C8.80714 13.3072 8.66666 13.6464 8.66666 14H7.33333V4.66667C7.33333 3.78261 7.68452 2.93477 8.30964 2.30965ZM8.66666 11.6906C9.0686 11.4586 9.52784 11.3333 9.99999 11.3333H14V2.66667H10.6667C10.1362 2.66667 9.62752 2.87738 9.25245 3.25246C8.87738 3.62753 8.66666 4.13624 8.66666 4.66667V11.6906Z" fill="#20222B"/>
</svg>


);

export default BookIcon;
