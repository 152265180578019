/** @jsx jsx */
import { jsx } from "theme-ui";
import { useCraft } from "./query";
import { Link } from "gatsby";

import Container from "@components/Container";
import ScrollContainer from "@components/ScrollContainer";
import ImageCard from "@components/ImageCard";
import IconCard from "@components/IconCard";
import PlainCard from "@components/PlainCard";
import { rem } from "@src/theme";
import { SearchBox } from "@components/SearchBox";
import Layout from "@src/components/Layout";
import imageprofile from "@src/assets/images/profile.png";
import SEO from "@components/seo";
import ArrowBg from "@src/components/ArrowBg";
export default (props) => {
  const { collections, insightsFor, socialImpacts, authors } = useCraft();
  return (
    <Layout>
      <SEO
        defaultTitle="Home"
        metadata={{
          metaDataDescription:
            "Find insights and research about social entrepreneurship",
        }}
        location={props.location}
      />
      <div
        sx={{
          bg: "lightGrey",
          pt: [rem(40), rem(60), rem(60)],
        }}
      >
        <Container>
          <ArrowBg>
            <div>
              <p
                sx={{
                  variant: "typography.paragraphM",
                }}
              >
                The Social Entrepreneurship Evidence Space
              </p>
              <h1
                sx={{
                  variant: "typography.headingXXL",
                  mt: rem(15),
                  mb: rem(30),
                  lineHeight: 1.15,
                  maxWidth: rem(790),
                }}
              >
                Find insights and research about social entrepreneurship
              </h1>
            </div>
            <div
              sx={{
                pb: [rem(20), rem(40), rem(80)],
              }}
            >
              <SearchBox submitMode={true} />
            </div>
          </ArrowBg>
        </Container>
      </div>
      <section
        sx={{
          pt: [rem(40), null, rem(80)],
          pb: [rem(40), null, rem(100)],
        }}
      >
        <Container>
          <h1
            sx={{
              variant: "typography.headingXL",
            }}
          >
            Impact areas
          </h1>
        </Container>

        <ScrollContainer>
          <ul
            sx={{
              width: ["calc(100vh + 50px)", "100%", "100%"],
              display: "flex",
              flexWrap: "wrap",
              pt: [rem(10), null, rem(15)],
            }}
          >
            {socialImpacts.map(({ title, slug }) => (
              <IconCard
                link={`/explore/social-impacts/${slug}`}
                label={title}
                key={title}
              />
            ))}
          </ul>
        </ScrollContainer>
      </section>

      <section
        sx={{
          pb: [rem(40), null, rem(60)],
        }}
      >
        <Container>
          <h1
            sx={{
              variant: "typography.headingXL",
            }}
          >
            Collections
          </h1>
        </Container>

        <ScrollContainer>
          <ul
            sx={{
              display: "flex",
              pt: [rem(10), null, rem(15)],
            }}
          >
            {collections.map(({ title, slug, description }) => (
              <ImageCard
                title={title}
                text={description}
                link={`/explore/collections/${slug}`}
                key={title}
              />
            ))}
          </ul>
        </ScrollContainer>
      </section>

      <section
        sx={{
          pb: [rem(40), null, rem(80)],
        }}
      >
        <Container>
          <h1
            sx={{
              variant: "typography.headingXL",
            }}
          >
            Insights for...
          </h1>
        </Container>

        <ScrollContainer>
          <ul
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: ["calc(100vh + 50px)", "100%", "100%"],
              pt: [rem(10), null, rem(15)],
            }}
          >
            {insightsFor.map(({ title, groupHandle, slug }) => (
              <PlainCard
                link={`/explore/insights-for/${slug}`}
                label={title}
                size="md"
                key={title}
              />
            ))}
          </ul>
        </ScrollContainer>
      </section>

      <section
        sx={{
          pb: rem(100),
        }}
      >
        <Container>
          <h1
            sx={{
              variant: "typography.headingXL",
            }}
          >
            Popular authors
          </h1>
        </Container>

        <ScrollContainer>
          <ul
            sx={{
              display: "flex",
              pt: [rem(10), null, rem(15)],
            }}
          >
            {authors && authors.length
              ? authors.map(({ title, img, slug }) => (
                  <li
                    sx={{
                      color: "black",
                      "> *": {
                        color: "black",
                      },
                      listStyle: "none",
                      mr: "24px",
                    }}
                    key={title}
                  >
                    <Link
                      to={`authors/${slug}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textDecoration: "none",

                        "&:hover": {
                          h3: {
                            textDecorationColor: "inherit",
                          },
                        },
                        h3: {
                          textDecoration: "underline",
                          textDecorationColor: "transparent",
                        },
                      }}
                    >
                      <div sx={{ flex: 1, textAlign: "center" }}>
                        {img[0] ? (
                          <img
                            src={img[0].url}
                            alt={img[0].alt}
                            sx={{
                              borderRadius: "50%",
                              width: "88px",
                              height: "88px",
                              "-webkit-filter": ["grayscale(100%)"],
                              filter: "grayscale(100%)",
                            }}
                          />
                        ) : (
                          <img src={imageprofile} />
                        )}
                      </div>
                      <div sx={{ flex: 1, textAlign: "center" }}>
                        <h3
                          sx={{
                            mt: rem(4),
                            mb: rem(10),
                            fontSize: rem(14),
                          }}
                        >
                          {title}
                        </h3>
                      </div>
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </ScrollContainer>
      </section>
    </Layout>
  );
};
