import { useStaticQuery, graphql } from "gatsby";
import { mapCraft } from "./mappings";

export const query = graphql`
  query ExploreQuery {
    craft {
      collections: categories(group: "collections") {
        title
        slug
        groupHandle
        ... on Craft_collections_Category {
          description
        }
      }

      socialImpacts: categories(group: "socialImpacts") {
        title
        slug
        groupHandle
      }

      insightsFor: categories(group: "insightsFor") {
        title
        slug
        groupHandle
      }
      authors: entries(type: "authors", showPopular: true) {
        title
        slug
        ... on Craft_author_authors_Entry {
          img {
            url
            title
          }
        }
      }
    }
  }
`;

export const useCraft = () => {
  const { craft } = useStaticQuery(query);
  return mapCraft(craft);
};
