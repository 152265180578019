import React from "react";

const DollarIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.66667 6.66666V13.3333H7.33333V6.66666H8.66667Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6667 2.66666V13.3333H11.3333V2.66666H12.6667Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.66667 10.6667V13.3333H3.33333V10.6667H4.66667Z" fill="#20222B"/>
</svg>
);

export default DollarIcon;
