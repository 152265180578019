/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { rem } from "@src/theme";
import collectionThumb from "@src/assets/images/collection.png";

const ImageCard = ({ title, text, link }) => (
  <li
    sx={{
      minHeight: "44px",
      color: "black",
      "> *": {
        color: "black",
      },

      mr: "16px",
    }}
  >
    <Link
      to={link}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: [rem(135), null, rem(192)],

        "&:hover": {
          h3: {
            textDecorationColor: "inherit",
          },
        },
        h3: {
          textDecoration: "underline",
          textDecorationColor: "transparent",
        },
      }}
    >
      <div sx={{ height: [rem(95), rem(132)], flex: 1 }}>
        <img
          src={`/images/${title}.jpg`}
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div sx={{ flex: 1 }}>
        <h3
          sx={{
            variant: "typography.headingM",
            mt: rem(14),
            mb: rem(10),
          }}
        >
          {title}
        </h3>
        <p
          sx={{
            variant: "typography.paragraphXS",
            m: 0,
          }}
        >
          {text}
        </p>
      </div>
    </Link>
  </li>
);
export default ImageCard;
