import React from "react";

const HouseIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.99999 0.488754L14.6667 5.67394V13.3333C14.6667 13.8638 14.4559 14.3725 14.0809 14.7475C13.7058 15.1226 13.1971 15.3333 12.6667 15.3333H3.33333C2.80289 15.3333 2.29419 15.1226 1.91911 14.7475C1.54404 14.3725 1.33333 13.8638 1.33333 13.3333V5.67394L7.99999 0.488754ZM2.66666 6.32605V13.3333C2.66666 13.5101 2.7369 13.6797 2.86192 13.8047C2.98695 13.9298 3.15652 14 3.33333 14H12.6667C12.8435 14 13.013 13.9298 13.1381 13.8047C13.2631 13.6797 13.3333 13.5101 13.3333 13.3333V6.32605L8 2.1779L2.66666 6.32605Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.33333 7.33334H10.6667V14.6667H9.33333V8.66667H6.66666V14.6667H5.33333V7.33334Z" fill="#20222B"/>
</svg>



);

export default HouseIcon;
