import React from "react";

const DollarIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33334 15.3333V0.666672H8.66667V15.3333H7.33334Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.21202 3.54534C4.77462 2.98273 5.53769 2.66666 6.33334 2.66666H11.3333V4H6.33334C5.89131 4 5.46739 4.17559 5.15482 4.48815C4.84226 4.80071 4.66667 5.22464 4.66667 5.66666C4.66667 6.10869 4.84226 6.53261 5.15482 6.84517C5.46739 7.15774 5.89131 7.33333 6.33334 7.33333H9.66667C10.4623 7.33333 11.2254 7.6494 11.788 8.21201C12.3506 8.77462 12.6667 9.53768 12.6667 10.3333C12.6667 11.129 12.3506 11.892 11.788 12.4547C11.2254 13.0173 10.4623 13.3333 9.66667 13.3333H4V12H9.66667C10.1087 12 10.5326 11.8244 10.8452 11.5118C11.1577 11.1993 11.3333 10.7754 11.3333 10.3333C11.3333 9.8913 11.1577 9.46738 10.8452 9.15482C10.5326 8.84226 10.1087 8.66666 9.66667 8.66666H6.33334C5.53769 8.66666 4.77462 8.35059 4.21202 7.78798C3.64941 7.22537 3.33334 6.46231 3.33334 5.66666C3.33334 4.87101 3.64941 4.10795 4.21202 3.54534Z" fill="#20222B"/>
</svg>



);

export default DollarIcon;
