import React from "react";

const SunIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 5.33333C6.52724 5.33333 5.33333 6.52724 5.33333 8C5.33333 9.47276 6.52724 10.6667 8 10.6667C9.47276 10.6667 10.6667 9.47276 10.6667 8C10.6667 6.52724 9.47276 5.33333 8 5.33333ZM4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33334 2.00001V0.666672H8.66667V2.00001H7.33334Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33334 15.3333V14H8.66667V15.3333H7.33334Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.28841 4.23122L2.34174 3.28455L3.28455 2.34174L4.23122 3.28841L3.28841 4.23122Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.7155 13.6583L11.7688 12.7116L12.7116 11.7688L13.6583 12.7155L12.7155 13.6583Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 8.66667H0.666664V7.33334H2V8.66667Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.3333 8.66667H14V7.33334H15.3333V8.66667Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.23122 12.7116L3.28455 13.6583L2.34174 12.7155L3.28841 11.7688L4.23122 12.7116Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.6583 3.28455L12.7116 4.23122L11.7688 3.28841L12.7155 2.34174L13.6583 3.28455Z" fill="#20222B"/>
</svg>


);

export default SunIcon;
